
html, body {
  font-family: 'nerislight', sans-serif;
}

b, strong {
  font-family: 'nerissemibold', sans-serif;
}

.title {
  line-height: normal;
  font-size: 7rem;
  font-family: 'nerisblack', sans-serif;
}