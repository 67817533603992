
@font-face {
  font-family: 'nerisblack';
  src: url('../../fonts/neris-black-webfont.woff2') format('woff2'),
  url('../../fonts/neris-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nerisblack_italic';
  src: url('../../fonts/neris-blackitalic-webfont.woff2') format('woff2'),
  url('../../fonts/neris-blackitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nerisbold_italic';
  src: url('../../fonts/neris-bolditalic-webfont.woff2') format('woff2'),
  url('../../fonts/neris-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nerislight';
  src: url('../../fonts/neris-light-webfont.woff2') format('woff2'),
  url('../../fonts/neris-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nerislight_italic';
  src: url('../../fonts/neris-lightitalic-webfont.woff2') format('woff2'),
  url('../../fonts/neris-lightitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nerissemibold';
  src: url('../../fonts/neris-semibold-webfont.woff2') format('woff2'),
  url('../../fonts/neris-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nerissemibold_italic';
  src: url('../../fonts/neris-semibolditalic-webfont.woff2') format('woff2'),
  url('../../fonts/neris-semibolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'neristhin';
  src: url('../../fonts/neris-thin-webfont.woff2') format('woff2'),
  url('../../fonts/neris-thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: '../../fonts/neristhin_italic';
  src: url('../../fonts/neris-thinitalic-webfont.woff2') format('woff2'),
  url('../../fonts/neris-thinitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}